import * as React from "react"

const Breadcrumbs = props => {
  return (
    <div
      className={
        props.modifier
          ? "c-breadcrumbs c-breadcrumbs--" + props.modifier
          : "c-breadcrumbs"
      }
    >
      <div className="container">
        <nav aria-label="Brödsmulor">
          <ol className="c-breadcrumbs__list">
            {props.crumbs &&
              props.crumbs.crumbs.map((crumb, index) => {
                if (index > 0)
                  return (
                    <li key={index} className="c-breadcrumbs__list__item">
                      <a href={crumb.pathname} aria-current="page">
                        {crumb.crumbLabel}
                      </a>
                    </li>
                  )
              })}
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default Breadcrumbs
