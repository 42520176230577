import * as React from "react"

const SectionHeader = props => {
  return (
    <div className="c-section-header">
      <h2 id={props.id} className="c-section-header__title">
        {props.text}
      </h2>
    </div>
  )
}

export default SectionHeader
