import * as React from "react"
import { Helmet } from "react-helmet"

import Breadcrumbs from "../../../components/breadcrumbs"
import Hero from "../../../components/hero"
import PageHeader from "../../../components/page-header"
import SliderWcag from "../../../components/slider-wcag"
import SectionHeader from "../../../components/section-header"
import Footer from "../../../components/footer"

import "../../../scss/site.scss"
//import StickyCta from "../../../components/sticky-cta"

const DemocraticPrinciples = props => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Demokratiska Principer | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/om-demokrati/demokratiska-principer"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/om-demokrati/demokratiska-principer"
        ></meta>
        <meta property="og:title" content="Demokratiska Principer"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <PageHeader></PageHeader>
        <div
          role="main"
          id="main"
          className="o-main c-main-background c-main-background--om-demokrati"
        >
          <Breadcrumbs
            modifier="indent"
            crumbs={props.pageContext.breadcrumb}
          />
          <Hero
            buttonLink="handledning"
            buttonText="Till läraren"
            modifier="has-breadcrumbs"
            title="Demokratiska principer"
          >
            <p>
              Här är ett bildspel med sju demokratiska principer. Men vad
              innebär de?
            </p>
            <p>Titta på bildspelet och diskutera tillsammans i klassen.</p>
          </Hero>
          <section className="c-content-section" aria-labelledby="principer">
            <SectionHeader id="principer" text="Principer" />
            <div>
              <div className="container c-content-section__container">
                <div className="c-content-section__row row">
                  <div className="col-xs-12">
                    <div className="c-content-section__content c-content-section__content--fluid">
                      <SliderWcag />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default DemocraticPrinciples
