import * as React from "react"
import { useState } from "react"
import ButtonPurple from "../components/button-purple"
import princip1 from "../images/demokratiska-principer/dp-princip-1.svg"
import princip2 from "../images/demokratiska-principer/dp-princip-2.svg"
import princip3 from "../images/demokratiska-principer/dp-princip-3.svg"
import princip4 from "../images/demokratiska-principer/dp-princip-4.svg"
import princip5 from "../images/demokratiska-principer/dp-princip-5.svg"
import princip6 from "../images/demokratiska-principer/dp-princip-6.svg"
import princip7 from "../images/demokratiska-principer/dp-princip-7.svg"
const arrowRight = require("../images/icons/dp-icon-arrow-link.svg")
const arrowLeft = require("../images/icons/dp-icon-arrow-link-left.svg")

const sliderImages = [
  {
    image: princip7,
    alt: "",
  },
  {
    image: princip1,
    alt: "Alla har samma värde",
  },
  {
    image: princip2,
    alt: "och alla ska mötas med respekt",
  },
  {
    image: princip3,
    alt: "Alla ska kunna delta",
  },
  {
    image: princip4,
    alt: "och alla får säga sin mening",
  },
  {
    image: princip5,
    alt: "Alla har en röst var",
  },
  {
    image: princip6,
    alt: "Majoriteten bestämmer",
  },
  {
    image: princip7,
    alt: "men minoriteten skall respekteras",
  },
]

const SliderWcag = () => {
  const [index, setIndex] = useState(0)

  return (
    <div className="c-slider">
      <div className="c-slider__content">
        <ul className="c-slider__slides">
          {index == 0 && (
            <li className="c-slider__slides__item c-slider__slides__item--start">
              <img
                className="c-slider__slides__item__img"
                src={sliderImages[0].image}
                alt={sliderImages[0].alt}
              />
              <ButtonPurple
                text="Visa första principen"
                onClick={() => setIndex(prevState => (prevState += 1))}
              />
            </li>
          )}
          {index > 0 && (
            <li className="c-slider__slides__item">
              <img
                className="c-slider__slides__item__img"
                src={sliderImages[index].image}
                alt={sliderImages[index].alt}
              />
            </li>
          )}
        </ul>
      </div>
      <ul className="c-slider__controls">
        <li>
          <button
            type="button"
            className="c-slider__controls__button c-slider__controls__button--previous"
          >
            {index > 1 && (
              <img
                alt="Föregående bild"
                src={arrowLeft}
                onClick={() => setIndex(prevState => (prevState -= 1))}
              />
            )}
          </button>
        </li>
        <li>
          <button
            type="button"
            className="c-slider__controls__button c-slider__controls__button--next"
          >
            {index > 0 && index < 7 && (
              <img
                alt="Nästa bild"
                src={arrowRight}
                onClick={() => setIndex(prevState => (prevState += 1))}
              />
            )}
          </button>
        </li>
      </ul>
      <ul className="c-slider__navigation">
        {[...Array(7)].map((x, i) => (
          <li key={i} className="c-slider__navigation__item">
            <button
              className={`c-slider__navigation__button ${
                i < index ? "c-slider__navigation__button--visited" : ""
              }`}
              onClick={() => setIndex(() => i + 1)}
            >
              <span className="u-visually-hidden">
                Bild {i + 1}: {sliderImages[i + 1].alt}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SliderWcag
