import React from "react"
import Link from "gatsby-link"
const arrowIcon = require("../images/icons/dp-icon-arrow-link.svg")

export default function ButtonLink(props) {
  return (
    <Link to={props.to} className="c-button" activeClassName="-active">
      <span className="c-button__text">{props.text}</span>
      <img className="c-button__img" src={arrowIcon} alt="" />
    </Link>
  )
}
