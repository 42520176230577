import * as React from "react"
import ButtonLink from "../components/button-link"

const Hero = props => {
  return (
    <header
      className={props.modifier ? "c-hero c-hero--" + props.modifier : "c-hero"}
    >
      <div className="container">
        <div className="c-hero__content">
          <h1 className="c-hero__title">{props.title}</h1>
          <div className="c-hero__preamble">{props.children}</div>
          {props.buttonLink && (
            <ButtonLink to={props.buttonLink} text={props.buttonText} />
          )}
        </div>
      </div>
    </header>
  )
}

export default Hero
